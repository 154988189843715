/**
 * Copied from https://mui.com/material-ui/react-autocomplete/#virtualization
 * To be used with the Autocomplete component from MUI.
 */
import { autocompleteClasses, Popper, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import { ListChildComponentProps, VariableSizeList } from "react-window"
import { styled } from "@mui/material/styles"

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props
    const dataSet = data[index]
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    }

    return (
        <Typography component="li" {...dataSet.props} noWrap style={inlineStyle}>
            {dataSet.label}
        </Typography>
    )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null)
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true)
        }
    }, [data])
    return ref
}

// Adapter for react-window
export const ListboxVirtualizationComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
        const { children, ...other } = props
        const itemData: React.ReactElement[] = []
        ;(children as React.ReactElement[]).forEach(
            (item: React.ReactElement & { children?: React.ReactElement[] }) => {
                itemData.push(item)
                itemData.push(...(item.children || []))
            },
        )

        const theme = useTheme()
        const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
            noSsr: true,
        })
        const itemCount = itemData.length
        const itemSize = smUp ? 36 : 48

        const getChildSize = (child: React.ReactElement) => {
            if (child.hasOwnProperty("group")) {
                return 48
            }

            return itemSize
        }

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
        }

        const gridRef = useResetCache(itemCount)

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        // @ts-expect-error Copied from MUI, not looking into this
                        itemSize={(index) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        )
    },
)

export const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
})
