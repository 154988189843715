import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react"
import { LoadResponseDTOReportingDataSetDTO } from "generated/models"
import {
    AppContextDTO,
    BooleanClauseDTO,
    PageableDTO,
    QuerySettingsDTO,
    SortSettingsDTO,
    StringValueClauseDTO,
    TimespanSettingsDTO,
} from "generated/models"
import ReportingService from "shared/service/ReportingService"
import { storeWidgetSettings } from "domain/widget/redux/widget.slice"
import store from "shared/redux/store"
import EmbeddingUtil from "shared/util/EmbeddingUtil"
import { MESSAGE } from "domain/messaging/MessageListener"
import { ContainerSizeContextProvider } from "domain/widget/ContainerSizeContext"
import { ToolsContext } from "domain/widget/ToolsContext"
import ConditionClauseService from "shared/service/conditionClauseService"
import axios, { CancelTokenSource } from "axios"
import { log } from "shared/util/log"
import DimensionService from "domain/dimension/service/DimensionService"
import WidgetDataUtil from "domain/widget/WidgetDataUtil"
import { BooleanOperator, ClauseType, ConditionClauseType, ErrorResponse, FilterState } from "domain/types"
import { TopNWidgetSettingsDTO, QueryWidgetSettingsDTO } from "domain/types/backend/widget.types"
import WidgetFactory from "domain/widget/WidgetFactory"

type WidgetContextProviderProperties = {
    widgetSettings?: QueryWidgetSettingsDTO
    children?: ReactNode
}

type WidgetContextProperties = WidgetContextProviderProperties & {
    updateWidgetSettings?: (widgetSettings?: QueryWidgetSettingsDTO) => void
    response?: LoadResponseDTOReportingDataSetDTO
    isLoading: boolean
    isLegendButtonVisible: boolean
    updateIsLegendButtonVisible?: (visible: boolean) => void
    cancelTokenSource?: CancelTokenSource
}

export const WidgetContext = createContext<WidgetContextProperties>({ isLoading: false, isLegendButtonVisible: false })

/**
 * widgetSettings and response belong together,
 * because if some columns in the widget settings will be changed,
 * then the settings and the response don't match
 * and the widget becomes unstable. So widgetSettings and response must
 * be updated synchronously in one state object.
 */
type WidgetSettingsAndResponseState = {
    widgetSettings: QueryWidgetSettingsDTO
    response?: LoadResponseDTOReportingDataSetDTO | ErrorResponse
}

/**
 * This context provides row data for underlying children widgets
 *
 * @param props
 * @constructor
 */
export const WidgetContextProvider: React.FC<WidgetContextProviderProperties> = (
    props: WidgetContextProviderProperties,
): JSX.Element => {
    const toolsContext = useContext(ToolsContext)

    const widgetSettingsFromState = props.widgetSettings
    const widgetSettings = widgetSettingsFromState?.querySettings
        ? widgetSettingsFromState
        : WidgetDataUtil.extractWidgetSettingsFromUrl(window.location.href)

    /**
     * Creates a new object of widgetSettings with applied timespanSettings and filters
     *
     * @param widgetSettings
     * @param timespanSettings
     * @param filters
     * @param appContext
     */
    const applyTimespanAndFilters = (
        widgetSettings: QueryWidgetSettingsDTO,
        timespanSettings?: TimespanSettingsDTO,
        filters?: FilterState[],
        appContext?: AppContextDTO,
    ) => {
        return timespanSettings || filters
            ? {
                  ...widgetSettings,
                  querySettings: {
                      ...widgetSettings.querySettings,
                      appContext: appContext,
                      timespanSettings: timespanSettings,
                      filter: filters
                          ? ConditionClauseService.buildFilterQuery(filters, null)
                          : widgetSettings.querySettings?.filter,
                  },
              }
            : widgetSettings
    }

    // this reference will be set on the widget and used e.g. in the [ContainerSizeContext] to compute the size of the widget
    const widgetContainerRef = useRef<any>()
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null)
    const [widgetSettingsAndResponseState, setWidgetSettingsAndResponseState] =
        useState<WidgetSettingsAndResponseState>({
            widgetSettings: applyTimespanAndFilters(
                widgetSettings,
                toolsContext?.timespanSettings,
                toolsContext?.filterStates,
                store.getState().appContext.appContext,
            ),
        })
    const [isLoading, updateIsLoading] = useState(false)
    const [isLegendButtonVisible, updateIsLegendButtonVisible] = useState(false)

    useEffect(() => {
        setWidgetSettingsAndResponseState((prev) => {
            const result = {
                ...prev,
                widgetSettings: applyTimespanAndFilters(
                    widgetSettings,
                    toolsContext?.timespanSettings,
                    toolsContext?.filterStates,
                    store.getState().appContext.appContext,
                ),
            }

            // reset the response data only if query settings have been changed
            if (querySettingsChanged(prev.widgetSettings.querySettings, result.widgetSettings.querySettings)) {
                result.response = null
            }

            return result
        })
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // TODO: ESLint does not like complex dependency expressions. Can we simplify this?
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(widgetSettings),
        toolsContext?.timespanSettings,
        toolsContext?.filterStates,
        // TODO: ESLint does not like complex dependency expressions. Can we simplify this?
        // eslint-disable-next-line react-hooks/exhaustive-deps
        store.getState().appContext.appContext,
    ])

    useEffect(() => {
        if (!widgetSettingsAndResponseState.response) {
            // if there is no data yet: start loading data
            updateIsLoading(true)
            loadData()
        }

        // We must serialize the dependency objects to avoid reloading "useEffect" function
        // after the props have been regenerated with identical values
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(widgetSettingsAndResponseState.widgetSettings?.querySettings)])

    /**
     * Loads the data and stores the result in the state.
     */
    const loadData = () => {
        const newCancelTokenSource = axios.CancelToken.source()
        setCancelTokenSource((prev) => {
            // cancel the possible running previous request
            prev?.cancel()
            return newCancelTokenSource
        })

        const isTopNWidget = Object.keys(widgetSettingsAndResponseState.widgetSettings).indexOf("topNElements") >= 0
        if (isTopNWidget) {
            loadTopNDataAndStoreState(
                widgetSettingsAndResponseState.widgetSettings as TopNWidgetSettingsDTO,
                newCancelTokenSource,
            )
        } else {
            loadDataAndStoreState(widgetSettingsAndResponseState.widgetSettings, newCancelTokenSource)
        }
    }

    /**
     * Loads data from [apiPath] with [querySettings] and [cancelTokenSource]. Afterwards stores the response in the state.
     *
     * @param widgetSettings
     * @param cancelTokenSource
     */
    const loadDataAndStoreState = (widgetSettings: QueryWidgetSettingsDTO, cancelTokenSource: CancelTokenSource) => {
        const { querySettings, apiPath } = widgetSettings
        ReportingService.loadData(apiPath || "reporting/loadData", querySettings, cancelTokenSource)
            .then(dataLoadedHandler)
            .catch(errorHandler)
    }

    /**
     * Loads top n values of the second dimension (column 2) for the metric (column 3) and then loads the main query filtered by the
     * top n entries. Afterwards stores the filtered response in the state.
     *
     * @param widgetSettings
     * @param cancelTokenSource
     */
    const loadTopNDataAndStoreState = (widgetSettings: TopNWidgetSettingsDTO, cancelTokenSource: CancelTokenSource) => {
        const { querySettings, apiPath, topNElements } = widgetSettings

        const secondDimensionColumnName = querySettings.columnNames[1]
        const metricColumnName = querySettings.columnNames[2]
        const secondDimensionIdentifier = DimensionService.recognizeDimensionField(secondDimensionColumnName).identifier

        const topNQuerySettings = {
            ...querySettings,
            columnNames: [DimensionService.getDimensionValueColumn(secondDimensionIdentifier), metricColumnName],
            paginationSettings: {
                page: 0,
                pageSize: topNElements,
            } as PageableDTO,
            sortSettings: {
                sortAscending: false,
                sortProperties: [metricColumnName],
            } as SortSettingsDTO,
        }

        // loading top n values
        ReportingService.loadData(apiPath || "reporting/loadData", topNQuerySettings, cancelTokenSource)
            .then((topNResponse: LoadResponseDTOReportingDataSetDTO) => {
                const newTopNValues = topNResponse.dataSet.rows.map(
                    (row) => row[secondDimensionIdentifier].name || row[secondDimensionIdentifier].value,
                )

                if (newTopNValues.length > 0) {
                    const mainQuerySettings: QuerySettingsDTO = {
                        ...querySettings,
                        filter: ConditionClauseService.combineFilterQueries([
                            querySettings.filter,
                            {
                                operator: BooleanOperator.OR,
                                clauseType: ConditionClauseType.BOOLEAN,
                                clauses: newTopNValues.map((value) => {
                                    return {
                                        columnName: DimensionService.getDimensionValueColumn(secondDimensionIdentifier),
                                        value: value === undefined ? "__NULL__" : value,
                                        type: ClauseType.EQUALS,
                                        clauseType:
                                            value === undefined || isNaN(Number(value.toString()))
                                                ? ConditionClauseType.STRING
                                                : ConditionClauseType.NUMBER,
                                    } as StringValueClauseDTO
                                }),
                            } as BooleanClauseDTO,
                        ]),
                    }

                    // load main data filtered by the top n values
                    loadDataAndStoreState(
                        { querySettings: mainQuerySettings, apiPath: apiPath } as QueryWidgetSettingsDTO,
                        cancelTokenSource,
                    )
                } else {
                    log.debug("No top n data found")
                    setWidgetSettingsAndResponseState((prev) => {
                        return { ...prev, response: topNResponse }
                    })
                    updateIsLoading(false)
                }
            })
            .catch(errorHandler)
    }

    /**
     * This handler will be executed after the data loading is done
     *
     * @param response
     */
    const dataLoadedHandler = (response: LoadResponseDTOReportingDataSetDTO | ErrorResponse) => {
        setWidgetSettingsAndResponseState((prev) => {
            return { ...prev, response: response }
        })
        updateIsLoading(false)
    }

    const errorHandler = (response: ErrorResponse) => {
        if (response?.errors && response.errors.indexOf("Cancel") >= 0) {
            log.debug("request was canceled")
        } else {
            dataLoadedHandler(response)
        }
    }

    /**
     * Checks whether querySettings have been changed
     */
    const querySettingsChanged = (prevQuerySettings: QuerySettingsDTO, newQuerySettings: QuerySettingsDTO): boolean => {
        return JSON.stringify(prevQuerySettings) !== JSON.stringify(newQuerySettings)
    }

    const context = {
        widgetSettings: widgetSettingsAndResponseState.widgetSettings,
        updateWidgetSettings: (widgetSettings?: QueryWidgetSettingsDTO) => {
            setWidgetSettingsAndResponseState((prev) => {
                return { ...prev, widgetSettings: widgetSettings }
            })
            store.dispatch(storeWidgetSettings({ widgetSettings }))
            EmbeddingUtil.sendMessage(MESSAGE.WIDGET_SETTINGS_CHANGED, widgetSettings)
        },
        response: widgetSettingsAndResponseState.response,
        isLoading: isLoading,
        isLegendButtonVisible: isLegendButtonVisible,
        updateIsLegendButtonVisible: updateIsLegendButtonVisible,
        cancelTokenSource: cancelTokenSource,
    } as WidgetContextProperties

    return (
        <WidgetContext.Provider value={context}>
            <ContainerSizeContextProvider containerRef={widgetContainerRef}>
                {WidgetFactory.create(widgetSettingsAndResponseState.widgetSettings.type)}
            </ContainerSizeContextProvider>
        </WidgetContext.Provider>
    )
}
