import _includes from "lodash/includes"
import _without from "lodash/without"
import {
    BooleanClauseDTO,
    BooleanValueClauseDTO,
    ConditionClauseDTO,
    NotClauseDTO,
    NumberSetClauseDTO,
    NumberValueClauseDTO,
    SelectFormElementDTO,
    StringSetClauseDTO,
    StringValueClauseDTO,
} from "generated/models"
import { groupBy } from "shared/util/util"
import { ElementSetting } from "shared/component/layout/context/RootElementContext"
import {
    BooleanOperator,
    ClauseType,
    ConditionClauseType,
    FilterState,
    FilterType,
    NullabilityClauseDTO,
    SearchTerm,
    TrueClauseDTO,
} from "domain/types"

export const DEFAULT_FILTER_VALUES_PAGE_SIZE = 50000

const buildFilterState = (values: any[], dimensionIdentifier: string, multiSelect: boolean = false): FilterState => {
    return {
        selectFormElement: {
            formFieldConfig: { dimensionIdentifier: dimensionIdentifier },
            selectConfig: { multiSelect: multiSelect },
        } as SelectFormElementDTO,
        value: values,
    } as FilterState
}

/**
 * This function filters [conditionClause] for the [supportedFilterColumns]. The result contains only clauses that have one
 * of the [supportedFilterColumns].
 *
 * @param conditionClause
 * @param supportedFilterColumns
 */
const filterConditionClauseBySupportedFilters = (
    conditionClause: ConditionClauseDTO,
    supportedFilterColumns: string[],
): ConditionClauseDTO => {
    if (!conditionClause) {
        return null
    } else if (isBooleanClauseDTO(conditionClause)) {
        const filteredClauses = conditionClause.clauses
            .map((clause) => filterConditionClauseBySupportedFilters(clause, supportedFilterColumns))
            .filter((clause) => clause != null)
        if (filteredClauses.length > 0) {
            return {
                ...conditionClause,
                clauses: filteredClauses,
            } as BooleanClauseDTO
        } else {
            return null
        }
    } else {
        if (isColumnNameClauseDTO(conditionClause)) {
            if (supportedFilterColumns.indexOf(conditionClause.columnName) >= 0) {
                return conditionClause
            } else {
                return null
            }
        } else {
            return null
        }
    }
}

/**
 * Extracts conditionClause values for the required dimension
 *
 * @param conditionClause
 * @param dimension
 */
const getAllDimensionValuesFromFilter = (conditionClause: ConditionClauseDTO, dimension: string): any[] => {
    if (conditionClause === undefined) {
        return []
    }

    if (isBooleanClauseDTO(conditionClause)) {
        return [].concat(...conditionClause.clauses.map((clause) => getAllDimensionValuesFromFilter(clause, dimension)))
    } else {
        if (isStringSetClauseDTO(conditionClause) && conditionClause.columnName == dimension) {
            return Array.from(conditionClause.values || [])
        } else if (isNumberSetClauseDTO(conditionClause) && conditionClause.columnName == dimension) {
            return Array.from(conditionClause.values || [])
        } else if (
            (isNumberValueClauseDTO(conditionClause) || isStringValueClauseDTO(conditionClause)) &&
            conditionClause.columnName == dimension
        ) {
            return [conditionClause.value]
        }
    }

    return []
}

const buildFilterQuery = (filters: FilterState[], search?: SearchTerm): ConditionClauseDTO | undefined => {
    const mainClause: BooleanClauseDTO = {
        operator: BooleanOperator.AND,
        clauseType: ConditionClauseType.BOOLEAN,
        clauses: [],
    }

    if (filters && filters.length) {
        mainClause.clauses = filters
            .filter((filter) => {
                if (filter.value == null) {
                    return false
                }

                if (Array.isArray(filter.value)) {
                    return filter.value.length > 0
                }

                return true
            })
            .map(buildFilterClause)
            .filter(isDefined)
    }

    if (search && search.columns && search.searchTerm && search.searchTerm.length) {
        mainClause.clauses.push({
            clauses: search.columns.map((column) => ({
                columnName: column,
                value: search.searchTerm,
                type: ClauseType.CONTAINS,
                clauseType: ConditionClauseType.STRING,
            })),
            clauseType: ConditionClauseType.BOOLEAN,
            operator: BooleanOperator.OR,
        } as BooleanClauseDTO)
    }

    mainClause.clauses = _without(mainClause.clauses, undefined)

    return mainClause.clauses.length ? mainClause : undefined
}

/**
 * Combines current {@param filters} from the toolbar, supported {@param additionalFilters} and {@param searchTerm} filters
 */
const getCombinedFilterClause = (
    filters: FilterState[],
    additionalFilters: ConditionClauseDTO,
    searchTerm?: SearchTerm,
): ConditionClauseDTO => {
    const filter = buildFilterQuery(filters, searchTerm)

    return combineFilterQueries([filter, additionalFilters])
}

/**
 * Combines all elements of the array {@param filterQueries} as Boolean-AND clause.
 * If {@param filterQueries} contains only one non-null element then the function returns this element.
 *
 * @param filterQueries
 */
const combineFilterQueries = (filterQueries: ConditionClauseDTO[]): ConditionClauseDTO => {
    const filteredFilterQueries = filterQueries?.filter((fq) => fq !== null && fq !== undefined)

    if (filteredFilterQueries?.length > 1) {
        return {
            operator: BooleanOperator.AND,
            clauseType: ConditionClauseType.BOOLEAN,
            clauses: filteredFilterQueries,
        } as BooleanClauseDTO
    } else if (filteredFilterQueries?.length == 1) {
        return filteredFilterQueries[0]
    } else {
        return undefined
    }
}

const buildFilterClause = (filter: FilterState): ConditionClauseDTO | undefined => {
    const filterClause: BooleanClauseDTO = {
        clauses: [],
        clauseType: ConditionClauseType.BOOLEAN,
        operator: BooleanOperator.OR,
    }

    const filterType = filter.selectFormElement?.selectConfig
        ? filter.selectFormElement.selectConfig.multiSelect
            ? FilterType.MULTI_SELECT
            : FilterType.SINGLE_SELECT
        : FilterType.TEXT

    const valueColumn =
        filterType === FilterType.TEXT
            ? filter?.inputConfig?.valueColumn
            : filter?.selectFormElement?.formFieldConfig.dimensionIdentifier

    if (Array.isArray(filter.value)) {
        const filterValueArray = filter.value as Array<string | number>
        if (filterValueArray.length > 1) {
            // treat null-values separately
            if (_includes(filterValueArray, null)) {
                filterClause.clauses = [
                    {
                        columnName: valueColumn,
                        clauseType: ConditionClauseType.IS_NULL,
                    } as NullabilityClauseDTO,
                ]
            }
            const values: any[] = _without(filterValueArray, null)

            // e.g. value=[1,2,"__NULL__",4]
            const groupedByIsNumber = groupBy(values, (value): boolean => typeof value === "number")
            // e.g. numberValues=[1,2,4]
            const numberValues = groupedByIsNumber.get(true)
            // e.g. stringValues=["__NULL__"]
            const stringValues = groupedByIsNumber.get(false)

            if (numberValues && numberValues.length > 0) {
                filterClause.clauses = [
                    ...filterClause.clauses,
                    {
                        columnName: valueColumn,
                        values: numberValues,
                        type: ClauseType.IN,
                        clauseType: ConditionClauseType.NUMBER_SET,
                    } as NumberSetClauseDTO,
                ]
            }

            if (stringValues && stringValues.length > 0) {
                if (stringValues.length > 1) {
                    filterClause.clauses = [
                        ...filterClause.clauses,
                        {
                            columnName: valueColumn,
                            values: stringValues,
                            type: ClauseType.IN,
                            clauseType: ConditionClauseType.STRING_SET,
                        } as StringSetClauseDTO,
                    ]
                } else {
                    filterClause.clauses = [
                        ...filterClause.clauses,
                        {
                            columnName: valueColumn,
                            value: stringValues[0],
                            type: ClauseType.EQUALS,
                            clauseType: ConditionClauseType.STRING,
                        } as StringValueClauseDTO,
                    ]
                }
            }
        } else {
            // filterValueArray has only 1 or 0 elements
            filterClause.clauses = filterValueArray.map((value) => {
                if (value === null) {
                    return { columnName: valueColumn, clauseType: ConditionClauseType.IS_NULL } as NullabilityClauseDTO
                }
                const clausePart = { columnName: valueColumn, value: value }
                if (filterType === FilterType.TEXT) {
                    return Object.assign(clausePart, {
                        clauseType: ConditionClauseType.STRING,
                        type: ClauseType.CONTAINS,
                    }) as StringValueClauseDTO
                } else {
                    const clauseType: ConditionClauseType =
                        (typeof value).toUpperCase() == "NUMBER"
                            ? ConditionClauseType.NUMBER
                            : ConditionClauseType.STRING
                    return Object.assign(clausePart, {
                        clauseType: clauseType,
                        type: ClauseType.EQUALS,
                    }) as NumberValueClauseDTO
                }
            })
        }
    } else {
        let clause: ConditionClauseDTO
        if (filter.value === null) {
            clause = { columnName: valueColumn, clauseType: ConditionClauseType.IS_NULL } as NullabilityClauseDTO
        } else {
            const clausePart = { columnName: valueColumn, value: filter.value }
            if (filterType === FilterType.TEXT) {
                clause = Object.assign(clausePart, {
                    clauseType: ConditionClauseType.STRING,
                    type: ClauseType.CONTAINS,
                }) as StringValueClauseDTO
            } else {
                clause = Object.assign(clausePart, {
                    clauseType: ConditionClauseType.NUMBER,
                    type: ClauseType.EQUALS,
                }) as NumberValueClauseDTO
            }
        }
        filterClause.clauses.push(clause)
    }

    const result = filterClause.clauses.length ? filterClause : undefined

    const additionalFilters = filter.selectFormElement ? filter.selectFormElement.additionalFilters : undefined

    return additionalFilters
        ? {
              clauses: [filter?.selectFormElement?.additionalFilters, result].filter(isDefined),
              clauseType: ConditionClauseType.BOOLEAN,
              operator: BooleanOperator.AND,
          }
        : result
}

/**
 * Checks whether conditionClause match contextElementSettings
 *
 * @param contextElementSettings
 * @param conditionClause
 */
const matchesDimensionValueToConditionClause = (
    contextElementSettings: ElementSetting[],
    conditionClause: ConditionClauseDTO,
): boolean => {
    if (isTrueClauseDTO(conditionClause)) {
        return true
    } else if (isBooleanClauseDTO(conditionClause)) {
        if (conditionClause.operator === BooleanOperator.AND) {
            return conditionClause.clauses.every((clause) =>
                matchesDimensionValueToConditionClause(contextElementSettings, clause),
            )
        } else if (conditionClause.operator === BooleanOperator.OR) {
            return conditionClause.clauses.some((clause) =>
                matchesDimensionValueToConditionClause(contextElementSettings, clause),
            )
        } else throw new Error(`${conditionClause.operator} boolean operator is not supported`)
    } else if (isNotClauseDTO(conditionClause)) {
        return !matchesDimensionValueToConditionClause(contextElementSettings, conditionClause.clause)
    } else if (isBooleanValueClauseDTO(conditionClause)) {
        return matchesSettings(conditionClause.columnName, conditionClause.value, contextElementSettings)
    } else if (isNumberValueClauseDTO(conditionClause) || isStringValueClauseDTO(conditionClause)) {
        return matchesSettings(conditionClause.columnName, conditionClause.value, contextElementSettings)
    } else if (isStringSetClauseDTO(conditionClause)) {
        return Array.from(conditionClause.values || []).some((value) =>
            matchesSettings(conditionClause.columnName, value, contextElementSettings),
        )
    } else if (isNumberSetClauseDTO(conditionClause)) {
        return Array.from(conditionClause.values || []).some((value) =>
            matchesSettings(conditionClause.columnName, value, contextElementSettings),
        )
    } else if (conditionClause.clauseType === ConditionClauseType.FALSE) {
        return false
    } else {
        throw new Error(`Unsupported ConditionClauseDTO found: ${conditionClause.clauseType}`)
    }
}

/**
 * Searches in contextElementSettings, whether there is some setting with property=columnName and value=columnValue
 *
 * @param columnName
 * @param columnValue
 * @param contextElementSettings
 */
const matchesSettings = (
    columnName: string,
    columnValue: string | number | boolean | { [key: string]: unknown },
    contextElementSettings: ElementSetting[],
): boolean =>
    contextElementSettings.some(
        (elementSetting) => elementSetting.key === columnName && elementSetting.value === columnValue,
    )

/**
 * Checks whether the clause is TrueClauseDTO
 *
 * @param clause
 */
const isTrueClauseDTO = (clause: ConditionClauseDTO) => {
    return (clause as TrueClauseDTO).clauseType == ConditionClauseType.TRUE
}

/**
 * Checks whether the clause is BooleanClauseDTO
 *
 * @param clause
 */
const isBooleanClauseDTO = (clause: ConditionClauseDTO): clause is BooleanClauseDTO => {
    const c = clause as BooleanClauseDTO
    return (
        c.clauseType == ConditionClauseType.BOOLEAN &&
        c.clauses !== undefined &&
        (c.operator == BooleanOperator.AND || c.operator == BooleanOperator.OR)
    )
}

/**
 * Checks whether the clause is NotClauseDTO
 *
 * @param clause
 */
const isNotClauseDTO = (clause: ConditionClauseDTO): clause is NotClauseDTO => {
    const c = clause as NotClauseDTO
    return c.clauseType == ConditionClauseType.NOT && c.clause !== undefined
}

export type ColumnNameClauseDTO = ConditionClauseDTO & {
    columnName: string
}

const isColumnNameClauseDTO = (clause: ConditionClauseDTO): clause is ColumnNameClauseDTO => {
    return Object.keys(clause).indexOf("columnName") > -1
}

const isNullabilityClause = (clause: ConditionClauseDTO): clause is NullabilityClauseDTO => {
    const c = clause as NullabilityClauseDTO
    return (
        (c.clauseType == ConditionClauseType.IS_NULL || c.clauseType == ConditionClauseType.IS_NOT_NULL) &&
        c.columnName !== undefined
    )
}

const isNumberValueClauseDTO = (clause: ConditionClauseDTO): clause is NumberValueClauseDTO => {
    const c = clause as NumberValueClauseDTO
    return (
        isColumnNameClauseDTO(c) &&
        c.type === ClauseType.EQUALS &&
        c.clauseType == ConditionClauseType.NUMBER &&
        typeof c.value === "number"
    )
}

const isBooleanValueClauseDTO = (clause: ConditionClauseDTO): clause is BooleanValueClauseDTO => {
    const c = clause as BooleanValueClauseDTO
    return isColumnNameClauseDTO(c) && c.type === ClauseType.EQUALS && typeof c.value === "boolean"
}

const isStringValueClauseDTO = (clause: ConditionClauseDTO): clause is StringValueClauseDTO => {
    const c = clause as StringValueClauseDTO
    return (
        isColumnNameClauseDTO(c) &&
        (c.type === ClauseType.EQUALS || c.type === ClauseType.CONTAINS) &&
        c.clauseType == ConditionClauseType.STRING &&
        typeof c.value === "string"
    )
}

const isNumberSetClauseDTO = (clause: ConditionClauseDTO): clause is NumberSetClauseDTO => {
    const c = clause as NumberSetClauseDTO
    return (
        isColumnNameClauseDTO(c) &&
        c.type === ClauseType.IN &&
        c.clauseType == ConditionClauseType.NUMBER_SET &&
        isNumberSet(c.values)
    )
}

const isStringSetClauseDTO = (clause: ConditionClauseDTO): clause is StringSetClauseDTO => {
    const c = clause as StringSetClauseDTO
    return (
        isColumnNameClauseDTO(c) &&
        c.type === ClauseType.IN &&
        c.clauseType == ConditionClauseType.STRING_SET &&
        isStringSet(c.values)
    )
}

const isNumberSet = (value: any): value is Set<string> => {
    if (value instanceof Array) {
        return value.every((item) => typeof item === "number")
    }
    return false
}

const isStringSet = (value: any): value is Set<string> => {
    if (value instanceof Array) {
        return value.every((item) => typeof item === "string")
    }
    return false
}

const isDefined = <T>(input: T | undefined | null): input is T => {
    return typeof input !== "undefined" && input !== null
}

const ConditionClauseService = {
    getAllDimensionValuesFromFilter,
    buildFilterState,
    buildFilterQuery: buildFilterQuery,
    combineFilterQueries,
    matchesDimensionValueToConditionClause,
    filterConditionClauseBySupportedFilters,
    getCombinedFilterClause: getCombinedFilterClause,
}

export default ConditionClauseService
