import store from "shared/redux/store"
import { log } from "shared/util/log"
import { storeWidgetSettings } from "domain/widget/redux/widget.slice"
import { isQueryWidgetSettingsDTO, QueryWidgetSettingsDTO } from "domain/types/backend/widget.types"
import WidgetDataUtil from "domain/widget/WidgetDataUtil"

export const MESSAGE = {
    CHANGE_WIDGET_SETTINGS: "change-widget-settings",
    WIDGET_SETTINGS_CHANGED: "widget-settings-changed",
    OPEN_IFRAME_WINDOW: "OPEN_IFRAME_WINDOW",
    OPEN_HELP_ARTICLE: "OPEN_HELP_ARTICLE",
    TRACK_EVENT: "TRACK_EVENT",
    TRACK_USER_DATA: "TRACK_USER_DATA",
    OPEN_COLUMN_CONFIGURATOR: "OPEN_COLUMN_CONFIGURATOR",
}

/**
 * Central point for handling incoming easyXDM messages.
 *
 * The old core-ui uses easyXDM to signal app context (previously known as "app filter")
 * changes to our React components.
 */
class MessageListener {
    /**
     * Listens for various message types and dispatches accordingly.
     *
     * @param message
     */
    onMessage = (message: { type: string; body: any }) => {
        if (message.type === MESSAGE.CHANGE_WIDGET_SETTINGS) {
            let widgetSettings = message.body
            if (isQueryWidgetSettingsDTO(widgetSettings)) {
                widgetSettings = WidgetDataUtil.sanitizeWidgetSettings(widgetSettings)
            }

            log.debug("Dispatching a WidgetSettings change:", widgetSettings)
            store.dispatch(storeWidgetSettings({ widgetSettings }))
        }
    }
}

const messageListener = new MessageListener()

declare global {
    interface Window {
        messageListenerOnMessage: (message: { type: string; body: any }) => void
    }
}

// We save messageListener.onMessage in the window object to make it globally accessible.
// We need this to switch pages in the cypress tests.
window.messageListenerOnMessage = messageListener.onMessage

export default messageListener
