import React from "react"
import {
    DataGridPro,
    DataGridProProps,
    GridColDef,
    GridColumnMenuPinningItem,
    GridColumnMenuProps,
    GridNoRowsOverlay,
} from "@mui/x-data-grid-pro"
import { ColumnConfigDTO, LoadResponseDTOReportingDataSetDTO, PaginationInfoDTO } from "generated/models"
import DimensionService from "domain/dimension/service/DimensionService"
import { GridColumnMenuLeftPinningItem } from "shared/component/mui/datagrid/GridColumnMenuLeftPinningItem"
import { Pagination } from "shared/component/pagination/Pagination"
import type { UseQueryResult } from "@tanstack/react-query"
import { GridProSlotProps } from "@mui/x-data-grid-pro/models/gridProSlotProps"
import { GridProColumnMenu } from "@mui/x-data-grid-pro/components/GridProColumnMenu"
import { run } from "shared/util/FunctionUtil"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { GridDataRowDTO } from "domain/types"
import { Skeleton } from "@mui/material"

export type CustomizedDataGridProps = {
    visibleColumns: ReadonlyArray<ColumnConfigDTO>
    queryResult: UseQueryResult<LoadResponseDTOReportingDataSetDTO, Error>
    onPageChange: (page: number, pageSize: number) => void
    muiDataGridProps?: Partial<DataGridProProps>
    onContextMenu?: (event: React.MouseEvent) => void
    makeGridColDef?: (dataColumn: ColumnConfigDTO) => GridColDef
    pinning?: "left-right" | "left"
    paginationQueryResult?: UseQueryResult<PaginationInfoDTO>
}

declare module "@mui/x-data-grid-pro" {
    interface ColumnMenuPropsOverrides {
        pinning: "left-right" | "left"
    }
}

export const CustomizedDataGrid = ({
    visibleColumns,
    queryResult,
    onPageChange,
    muiDataGridProps,
    onContextMenu,
    makeGridColDef = simpleGridColDef,
    pinning = "left-right",
    paginationQueryResult,
}: CustomizedDataGridProps) => {
    const columns = visibleColumns.map(makeGridColDef)
    const { isPending, isSuccess, isError, isFetching, data } = queryResult

    const rows = isSuccess
        ? data.dataSet.rows.map((row, index) => ({
              ...makeRow(row),
              id: index,
          }))
        : []

    const slotProps: GridProSlotProps = {
        columnMenu: {
            pinning: pinning,
        },
    }

    if (onContextMenu) {
        slotProps.row = {
            onContextMenu: onContextMenu,
        }
    }

    return (
        <>
            <DataGridPro
                columns={columns}
                rows={rows}
                disableRowSelectionOnClick
                loading={isPending || isFetching}
                hideFooter={true}
                sx={{ minHeight: "400px" }}
                slots={{
                    noRowsOverlay: isError ? ErrorOverlay : GridNoRowsOverlay,
                    columnMenu: CustomColumnMenu,
                    detailPanelExpandIcon: ExpandMoreIcon,
                    detailPanelCollapseIcon: ExpandLessIcon,
                }}
                slotProps={slotProps}
                {...muiDataGridProps}
            />
            {!paginationQueryResult && isSuccess && data.paginationInfo && (
                <Pagination
                    page={data.paginationInfo.page}
                    pageSize={data.paginationInfo.pageSize}
                    totalEntities={data.paginationInfo.totalEntities}
                    onPageChange={onPageChange}
                    disabled={isFetching}
                />
            )}

            {paginationQueryResult && paginationQueryResult.isSuccess && paginationQueryResult.data && (
                <Pagination
                    page={paginationQueryResult.data.page}
                    pageSize={paginationQueryResult.data.pageSize}
                    totalEntities={paginationQueryResult.data.totalEntities}
                    onPageChange={onPageChange}
                    disabled={paginationQueryResult.isFetching}
                />
            )}
            {paginationQueryResult && !paginationQueryResult.isSuccess && paginationQueryResult.isFetching && (
                <Skeleton>
                    <Pagination page={0} pageSize={50} totalEntities={100} onPageChange={onPageChange} />
                </Skeleton>
            )}
        </>
    )
}

// TODO: Styling
const ErrorOverlay = () => {
    return <p>There was an error while fetching your data. Please try again later.</p>
}

const CustomColumnMenu = ({ pinning, ...props }: GridColumnMenuProps & { pinning: "left-right" | "left" }) => {
    const columnMenuPinningItem = run(() => {
        switch (pinning) {
            case "left-right":
                return GridColumnMenuPinningItem
            case "left":
                return GridColumnMenuLeftPinningItem
        }
    })

    return (
        <GridProColumnMenu
            {...props}
            slots={{
                // Hide the filter option in the column menu, as it only filters client-side
                columnMenuFilterItem: null,

                columnMenuPinningItem: columnMenuPinningItem,
            }}
        />
    )
}

const simpleGridColDef = (dataColumn: ColumnConfigDTO): GridColDef => {
    return {
        field: dataColumn.columnIdentifier,
        headerName: dataColumn.gridColumnProperties.columnHeader,
        width: dataColumn.gridColumnProperties.width || 150,
        sortable: dataColumn.gridColumnProperties.sortable,
    }
}

type ColumnIdentifier = string
const makeRow = (dataRow: GridDataRowDTO): Record<ColumnIdentifier, any> => {
    const result: Record<ColumnIdentifier, string> = {}
    Object.keys(dataRow).forEach((identifier) => {
        const columnData = dataRow[identifier]
        if (columnData?.value) {
            result[DimensionService.getDimensionValueColumn(identifier)] = columnData.value as string
        }
        if (columnData?.name) {
            result[DimensionService.getDimensionNameColumn(identifier)] = columnData.name
        }
    })
    return result
}
