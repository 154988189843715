import * as React from "react"
import { Pagination as MuiPagination, TablePagination } from "@mui/material"
import { GoToPagination } from "shared/component/pagination/GoToPagination"

type Props = {
    page: number
    pageSize: number
    totalEntities: number
    onPageChange: (newPage: number, newPageSize: number) => void
    disabled?: boolean
}

export const Pagination = ({ page, pageSize, totalEntities, onPageChange, disabled = false }: Props): JSX.Element => {
    const allPages = totalEntities >= 0 && pageSize > 0 ? Math.ceil(totalEntities / pageSize) : 0

    const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onPageChange(1, parseInt(event.target.value, 10))
    }

    return (
        <div style={{ display: "inline-flex", marginTop: "10px" }}>
            <MuiPagination
                count={allPages}
                page={page + 1}
                onChange={(_, newPage) => onPageChange(newPage, pageSize)}
                disabled={disabled}
            />
            <TablePagination
                style={{ marginTop: "1px" }}
                component={"div"}
                count={totalEntities >= 0 ? totalEntities : 0}
                page={page}
                onPageChange={(_, newPage) => onPageChange(newPage + 1, pageSize)}
                rowsPerPage={pageSize}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} items`}
                labelRowsPerPage={""}
                rowsPerPageOptions={[
                    { value: 10, label: "10 / page" },
                    { value: 25, label: "25 / page" },
                    { value: 50, label: "50 / page" },
                    { value: 100, label: "100 / page" },
                    { value: 250, label: "250 / page" },
                ]}
                onRowsPerPageChange={onRowsPerPageChange}
                ActionsComponent={() => null}
                disabled={disabled}
            />
            <GoToPagination
                totalPages={allPages}
                onPageChange={(page) => onPageChange(page, pageSize)}
                disabled={disabled}
            />
        </div>
    )
}
