import _includes from "lodash/includes"
import {
    ActionDTOIdentifierEnum,
    AppContextDTO,
    ColumnConfigDTO,
    DimensionDTO,
    GridConfigDTO,
    GridElementConfigDTO,
    PageableDTO,
    ReportingDataSetDTO,
} from "generated/models"
import { DataGridWidgetSettings, DataSettingsState } from "domain/widget/generic/GenericDataGridWidget"
import DataGridService from "domain/datagrid/service/datagrid.service"
import { ACTIONS_FIELD, ACTIONS_ID_FIELD, SELECTED_ID_FIELD } from "domain/datagrid/component/DataGrid"
import { toCamelCase } from "shared/util/util"
import { ReactElement } from "react"
import { DIMENSION_STATUS } from "shared/service/ActionService"
import FilterComponentUtil from "domain/filter/component/FilterComponentUtil"
import { AntTableColumn, SearchTerm, GridDataRowDTO, FilterState, Formatter } from "domain/types"
import { produce } from "immer"
import { filter, map } from "lodash"

/**
 * Checks whether some of submitted rows contains an disabled action with the action identifier
 *
 * @param rows
 * @param actionIdentifier
 */
function isActionDisabled(rows: GridDataRowDTO[], actionIdentifier: string): boolean {
    return (
        rows.filter(
            (row) =>
                row[ACTIONS_FIELD].data.actions.filter(
                    (action) => action.actionIdentifier === actionIdentifier && action.disabled,
                ).length > 0,
        ).length > 0
    )
}

/**
 * Returns the number of selected rows if all raws contain not disabled actions with the identifier
 * or 0 if some row contains disabled action with the identifier.
 * @param selectedRows
 * @param selRows
 * @param actionIdentifier
 */
function eligibleRowsForAction(selectedRows: number[], selRows: GridDataRowDTO[], actionIdentifier: string): number {
    if (selRows) {
        return isActionDisabled(selRows, actionIdentifier) ? 0 : selectedRows.length
    } else {
        return 0
    }
}

const eligibleRowsForEdit = (selectedRows: number[], selRows: GridDataRowDTO[]): number => {
    return eligibleRowsForAction(selectedRows, selRows, ActionDTOIdentifierEnum.EDIT)
}

const eligibleRowsForActivate = (selectedRows: number[], selRows: GridDataRowDTO[]): number => {
    const activateRows = eligibleRowsForAction(selectedRows, selRows, ActionDTOIdentifierEnum.ACTIVATE)

    return selRows.filter((row) => row[DIMENSION_STATUS]?.value !== 0).length > 0 ? 0 : activateRows
}

const eligibleRowsForDeactivate = (selectedRows: number[], selRows: GridDataRowDTO[]): number => {
    const deactivateRows = eligibleRowsForAction(selectedRows, selRows, ActionDTOIdentifierEnum.DEACTIVATE)

    return selRows.filter((row) => row[DIMENSION_STATUS]?.value !== 1).length > 0 ? 0 : deactivateRows
}

const eligibleRowsForDelete = (selectedRows: number[], selRows: GridDataRowDTO[]): number => {
    const deleteRows = eligibleRowsForAction(selectedRows, selRows, ActionDTOIdentifierEnum.DELETE)

    return selRows.filter((row) => row[DIMENSION_STATUS]?.value === 3).length > 0 ? 0 : deleteRows
}

const eligibleSelectedRows = (selectedRows: number[], rows: ReportingDataSetDTO): { [key: string]: number } => {
    const selRows: GridDataRowDTO[] = rows ? rows.rows.filter((_, rowIndex) => _includes(selectedRows, rowIndex)) : []

    return {
        [ActionDTOIdentifierEnum.EDIT]: eligibleRowsForEdit(selectedRows, selRows),
        [ActionDTOIdentifierEnum.ACTIVATE]: eligibleRowsForActivate(selectedRows, selRows),
        [ActionDTOIdentifierEnum.DEACTIVATE]: eligibleRowsForDeactivate(selectedRows, selRows),
        [ActionDTOIdentifierEnum.DELETE]: eligibleRowsForDelete(selectedRows, selRows),
    }
}

export const getWrapperClassName = (dimension: DimensionDTO) =>
    `datagrid-table-wrapper_${dimension ? dimension.identifier : ""}`

export const createDataSettings = (
    settings: DataGridWidgetSettings,
    gridElementConfig: GridElementConfigDTO,
    defaultOrderBy: string[],
    defaultSortAscending: boolean,
): DataSettingsState => {
    const config = DataGridService.mapGridConfig(gridElementConfig)
    const {
        hasSearch,
        requiredFilters,
        // dependsOn,
        defaultPagingSettings,
        defaultSortSettings,
        toolbar,
        actions,
        header,
        mainDimension,
        supportedSearchColumns,
        paths,
    } = config
    const { visiblePerDefaultColumns } = config

    const newSettings = produce(settings, (draft) => {
        draft.hasSearch = hasSearch
        draft.requiredFilters = Array.from(requiredFilters || [])
        // draft.dependsOn = dependsOn
        draft.toolbar = toolbar
        draft.actions = Array.from(actions || [])
        draft.title = header
        draft.paths = paths
        draft.dataManagerIdentifier = gridElementConfig.gridConfig.dataManagerIdentifier
    })

    const pagination = {
        page: defaultPagingSettings ? defaultPagingSettings.page : 0,
        pageSize: defaultPagingSettings ? defaultPagingSettings.pageSize : 25,
    } as PageableDTO

    return {
        mainDimension: mainDimension,
        columns: getVisiblePerDefaultColumnConfigs(gridElementConfig.gridConfig),
        supportedSearchColumns: Array.from(supportedSearchColumns || []),
        settings: newSettings,
        pagination: pagination,
        sortSettings: {
            sortAscending: defaultSortSettings ? defaultSortSettings.sortAscending : defaultSortAscending,
            sortProperties: defaultSortSettings ? defaultSortSettings.sortProperties : defaultOrderBy,
        },
    } as DataSettingsState
}

export const getVisiblePerDefaultColumnConfigs = (gridConfig: GridConfigDTO): ColumnConfigDTO[] => {
    return gridConfig.visiblePerDefaultColumns
        .map((columnIdentifier) => getGridColumnConfig(gridConfig, columnIdentifier))
        .filter((element) => element !== undefined)
}
export const getGridColumnConfig = (gridConfig: GridConfigDTO, columnIdentifier: string): ColumnConfigDTO | null => {
    return gridConfig.supportedColumnConfigs.find((columnConfig) => columnConfig.columnIdentifier === columnIdentifier)
}

export const getColumnNamesForShow = (columns: ColumnConfigDTO[]): string[] =>
    columns
        ? columns
              .filter((column) => !_includes([SELECTED_ID_FIELD, ACTIONS_ID_FIELD], column.columnIdentifier)) // don't ask for the checkbox and actions columns
              .map((column) => column.columnIdentifier)
        : []

const getSearchTerm = (
    columns: ColumnConfigDTO[],
    supportedSearchColumns: string[],
    searchTerm: string,
): SearchTerm => {
    const searchColumns = getColumnNamesForShow(columns).filter(
        (column: string) => supportedSearchColumns && supportedSearchColumns.indexOf(column) >= 0,
    )
    return { searchTerm: searchTerm, columns: searchColumns }
}

/**
 * Checks whether required filters are set, either over filter toolbar or over appContext
 */
export const areRequiredFiltersSet = (
    requiredFilters: DimensionDTO[],
    filters: FilterState[],
    appContext: AppContextDTO,
): boolean => {
    if (!requiredFilters || !requiredFilters.length) return true

    return requiredFilters.every((filterDimensionDTO) => {
        const filterId = toCamelCase(filterDimensionDTO.identifier) + "Id"
        if (appContext.hasOwnProperty(filterId) && appContext[filterId]) {
            return true
        }

        const filter = filters?.find(
            (filter) =>
                FilterComponentUtil.getFilterFormValueColumn(filter) === `${filterDimensionDTO.identifier}.value`,
        )

        return (
            filter &&
            ((Array.isArray(filter.value) && filter.value.length > 0) ||
                (!Array.isArray(filter.value) && filter.value !== undefined))
        )
    })
}

/**
 * Creates column configs (AntTableColumn[]) for the ant tables from ColumnConfigDTO[]
 *
 * @param columns
 * @param getRenderer - closure that returns renderer for the ColumnConfigDTO
 * @param getTitle - closure that returns ReactElement from ColumnConfigDTO
 * @param onClick - row onClick closure
 * @param getFixedProperty - supplier for column fixed property
 * @param getWidth - supplier for column width
 */
const mapColumns = (
    columns: ColumnConfigDTO[],
    getRenderer: (column: ColumnConfigDTO) => Formatter,
    getTitle: (column: ColumnConfigDTO) => ReactElement,
    onClick: (column: AntTableColumn) => () => void,
    getFixedProperty?: (column: ColumnConfigDTO, index: number) => string,
    getWidth?: (column: ColumnConfigDTO, index: number) => number,
): AntTableColumn[] => {
    const getEllipsis = (identifier: string): boolean | { showTitle?: boolean } => {
        return identifier === SELECTED_ID_FIELD || identifier === ACTIONS_ID_FIELD
            ? false
            : {
                  showTitle: false,
              }
    }

    return columns.map((columnConfig, index) => {
        const classNames = [
            columnConfig.columnIdentifier,
            columnConfig.gridColumnProperties.sortable ? "sortable" : "",
            columnConfig.gridColumnProperties.isMetric ? "is-metric" : "",
        ].join(" ")

        return {
            fixed: getFixedProperty ? getFixedProperty(columnConfig, index) : null,
            title: getTitle(columnConfig),
            ellipsis: getEllipsis(columnConfig.columnIdentifier),
            className: classNames,
            isSortable: columnConfig.gridColumnProperties.sortable,
            dataIndex: columnConfig.columnIdentifier,
            width: getWidth
                ? getWidth(columnConfig, index) || columnConfig.gridColumnProperties.width
                : columnConfig.gridColumnProperties.width,
            render: getRenderer(columnConfig),
            shouldCellUpdate: (record, prevRecord) => {
                // performance optimisation, possible since ant 4.3
                return (
                    !prevRecord ||
                    Object.keys(record).some((key) => {
                        return key !== ACTIONS_FIELD && record[key] !== prevRecord[key]
                    })
                )
            },
            onHeaderCell: (column: AntTableColumn) => ({
                width: column.width,
                onClick: onClick(column),
            }),
        } as AntTableColumn
    })
}

const GridUtil = {
    mapColumns: mapColumns,
    eligibleSelectedRows: eligibleSelectedRows,
    getSearchTerm: getSearchTerm,
}

export default GridUtil
