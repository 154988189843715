import formatter from "shared/util/formatter"
import { tooltipRenderer } from "shared/component/renderers/tooltip.renderer"
import { optimizationLevelRenderer } from "shared/component/renderers/optimization-level.renderer"
import { dataRenderer } from "shared/component/renderers/data.renderer"
import InlineButtonsRenderer from "shared/component/renderers/inline-buttons.renderer"
import React from "react"
import { iconRenderer } from "shared/component/renderers/icon.renderer"
import { ColumnConfigDTO, ColumnResponseDTO } from "generated/models"
import { prefixRenderer } from "shared/component/renderers/prefix.renderer"
import { NOT_AVAILABLE } from "Constants"
import MetricUtil from "domain/widget/MetricUtil"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { log } from "shared/util/log"
import { MetricDTO } from "domain/types"
import { RowActions } from "domain/actions/RowActions"
import { valueComparisonRenderer } from "shared/component/renderers/value-comparison.renderer"

export const columnRenderer = (columnConfigDTO: ColumnConfigDTO): GridCellFormatter => {
    const handleUndefinedValue = (columnResponseDTO: ColumnResponseDTO) => {
        if (!columnResponseDTO) return
        const isSet = (value: any) => value !== null && value !== undefined
        return {
            ...columnResponseDTO,
            value: isSet(columnResponseDTO.value)
                ? columnConfigDTO?.gridColumnProperties?.isMetric
                    ? MetricUtil.metricValueFormatter({ round: 2 } as MetricDTO)(columnResponseDTO.value)
                    : columnResponseDTO.value
                : NOT_AVAILABLE,
            name: isSet(columnResponseDTO.name) ? columnResponseDTO.name : NOT_AVAILABLE,
        }
    }
    const fn: Formatter = agnosticRenderer(columnConfigDTO)

    if (columnConfigDTO.gridColumnProperties.nullValuesVisible) {
        return (params: GridRenderCellParams<any, ColumnResponseDTO>) => fn(handleUndefinedValue(params.value))
    } else if (fn) {
        return (params: GridRenderCellParams<any, ColumnResponseDTO>) => fn(params.value)
    } else {
        return (params: GridRenderCellParams<any, ColumnResponseDTO>) => params.value.value
    }
}

export const agnosticRenderer = (columnConfigDTO: ColumnConfigDTO): Formatter => {
    const renderer = columnConfigDTO.gridColumnProperties.renderer

    let fn: Formatter
    switch (renderer.type) {
        case RendererType.VALUE:
            fn = (value) => tooltipRenderer(value?.value, renderer.cssClasses)
            break
        case RendererType.PREFIX_NAME:
            fn = (value) => tooltipRenderer(prefixRenderer(value), renderer.cssClasses)
            break
        case RendererType.POSTFIX_VALUE:
            fn = (value) => formatter.applyPostfix(value.value, columnConfigDTO.gridColumnProperties.postfix)
            break
        case RendererType.NAME:
            fn = (value) => tooltipRenderer(value?.name, renderer.cssClasses)
            break
        case RendererType.DATE:
            fn = (value) =>
                tooltipRenderer(formatter.formatDate(value?.value?.toString(), "DD.MM.YYYY"), renderer.cssClasses)
            break
        case RendererType.DATE_TIME:
            fn = (value) =>
                tooltipRenderer(
                    formatter.formatDateTime(value?.value?.toString(), "DD.MM.YYYY HH:mm"),
                    renderer.cssClasses,
                )
            break
        case RendererType.ICON:
            fn = (value) => iconRenderer(value)
            break
        // case RendererType.ROW_SELECTOR:
        //     fn = rowSelectorRenderer
        //     break
        // case RendererType.CONTEXT_MENU:
        //     fn = contextMenuRenderer
        //     break
        case RendererType.VALUE_COMPARISON:
            fn = valueComparisonRenderer
            break
        case RendererType.OPTIMIZATION_LEVEL:
            fn = optimizationLevelRenderer
            break
        case RendererType.DATA:
            fn = (value) => dataRenderer(value, renderer.cssClasses)
            break
        case RendererType.INLINE_BUTTONS:
            fn = (value) => (value ? <InlineButtonsRenderer rowActions={value.data as unknown as RowActions} /> : <></>)
            break
        default:
            log.error(`Renderer for type ${renderer.type} not found`)
            fn = undefined
    }

    return fn
}

export type Formatter = (value: ColumnResponseDTO) => any
export type GridCellFormatter = (params: GridRenderCellParams) => JSX.Element | string | number | boolean

export type ColumnRendererDTO = {
    type: RendererType
    cssClasses: string[]
}

export enum RendererType {
    // TEXT = 'TEXT',
    // NUMBER = 'NUMBER',
    // DATE = 'DATE',
    // DATE_TIME = 'DATE_TIME',
    // STATUS = 'STATUS',
    // BOOLEAN = 'BOOLEAN',
    // CSS_STYLED = 'CSS_STYLED',
    // TOOLTIP = 'TOOLTIP',
    ROW_SELECTOR = "ROW_SELECTOR",
    CONTEXT_MENU = "CONTEXT_MENU",
    OPTIMIZATION_LEVEL = "OPTIMIZATION_LEVEL",
    VALUE_COMPARISON = "VALUE_COMPARISON",
    // PERCENT = 'PERCENT',
    // JSON = 'JSON',
    // INLINE_BUTTONS = 'INLINE_BUTTONS',
    // HEALTH = 'HEALTH',
    DATE = "DATE",
    DATE_TIME = "DATE_TIME",
    INLINE_BUTTONS = "INLINE_BUTTONS",
    VALUE = "VALUE",
    PREFIX_NAME = "PREFIX_NAME",
    POSTFIX_VALUE = "POSTFIX_VALUE",
    NAME = "NAME",
    ICON = "ICON",
    ICON_NAME = "ICON_NAME",
    DATA = "DATA",
}
