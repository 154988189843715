import { ReactNode } from "react"
import _includes from "lodash/includes"
import { ActionDTOIdentifierEnum, FormConfigDTOTypeEnum, PathsDTO, ReportingDataSetDTO } from "generated/models"
import {
    FormConfigDTO,
    ActionDTO,
    ColumnResponseDTO,
    ConditionClauseDTO,
    DimensionDTO,
    SelectFormElementDTO,
} from "generated/models"
import { openPopupAction } from "shared/actions/open.popup.action"
import { message } from "antd"
import { log } from "shared/util/log"
import FormService from "shared/service/form.service"
import UrlService from "shared/service/url.service"
import { DataSettingsState } from "domain/widget/generic/GenericDataGridWidget"
import ConditionClauseService from "shared/service/conditionClauseService"
import { CancelTokenSource } from "axios"
import store from "shared/redux/store"
import FormUtil from "shared/util/FormUtil"
import { InlineButtonDTO } from "domain/actions/InlineButtonDTO"
import { ACTIONS_FIELD } from "domain/datagrid/component/DataGrid"
import { RowActions } from "domain/actions/RowActions"
import PopupTextUtil from "shared/util/PopupTextUtil"
import DimensionService, { DimensionFieldType } from "domain/dimension/service/DimensionService"
import { modalContent, modalLoadingState } from "shared/component/modals/redux/modals.slice"
import { ModalAction } from "shared/actions/modalAction"
import { ActionModalConfig, openActionModal } from "shared/component/modals/action/ActionModal.slice"
import { ActionPopupConfig, ActionType, DataRowDTO, FilterState, GridDataRowDTO, UIFormConfig } from "domain/types"

export const DIMENSION_STATUS = "status"

/**
 * Creates filter clause from the selected items and combines with the additional filters.
 * In create mode returns just additional filters without adding selected items
 *
 * @param action
 * @param additionalFilters
 * @param mainDimension
 * @param selectedItems
 */
const createPopupAdditionalFilterClause = (
    action: ActionDTO,
    additionalFilters: ConditionClauseDTO,
    mainDimension: DimensionDTO,
    selectedItems: GridDataRowDTO[],
): ConditionClauseDTO => {
    if (action.identifier == ActionDTOIdentifierEnum.CREATE) {
        return additionalFilters
    } else {
        // if rows in the grid are selected: use these as filters when loading the form (this is how we define which item(s) are being edited)
        const selectedItemsFilterState = [
            {
                selectFormElement: {
                    formFieldConfig: {
                        dimensionIdentifier: DimensionService.getDimensionValueColumn(mainDimension.identifier),
                    },
                    selectConfig: {},
                } as SelectFormElementDTO,
                value: selectedItems.map((row) => row[mainDimension.identifier].value), //items[mainDimension.identifier],
            } as FilterState,
        ]

        const selectedItemsFilterClause = ConditionClauseService.buildFilterQuery(selectedItemsFilterState)

        return ConditionClauseService.combineFilterQueries([selectedItemsFilterClause, additionalFilters])
    }
}

/**
 * Callback, that will be invoked after the form was submitted and the form has "keepOpenAfterCreateAndEdit=true"
 *
 * @param updatedItemData
 * @param actions
 * @param paths
 * @param mainDimension
 * @param cancelTokenSource
 * @param onAfterSubmit
 */
const editLastCreatedOrUpdatedItem = (
    updatedItemData: DataRowDTO[],
    actions: ActionDTO[],
    paths: PathsDTO,
    mainDimension: DimensionDTO,
    cancelTokenSource: CancelTokenSource,
    onAfterSubmit: () => void,
) => {
    if (updatedItemData?.length > 0) {
        const editAction = actions.find((value) => value.identifier === ActionDTOIdentifierEnum.EDIT)
        invokeAction(
            editAction,
            actions,
            paths,
            mainDimension,
            convertDataRowDTOToGridDataRowDTO(updatedItemData),
            cancelTokenSource,
            onAfterSubmit,
        )
    }
}

/**
 * Converts DataRowDTO[] to GridDataRowDTO[]
 * e.g. {channel.value: 123, channel.name: "ABC", advertiser.value: 456} -> {channel: {value: 123, name: "ABC"}, advertiser: {value: 456}}
 *
 * @param updatedItemData
 */
const convertDataRowDTOToGridDataRowDTO = (updatedItemData: DataRowDTO[]): GridDataRowDTO[] => {
    return updatedItemData.map((row) => {
        const gridRow = {} as GridDataRowDTO

        Object.keys(row).forEach((key) => {
            // channel.value
            const value = row[key] // 123
            const dimensionField = DimensionService.recognizeDimensionField(key)

            const columnResponseDTO: ColumnResponseDTO = gridRow[dimensionField.identifier]
                ? gridRow[dimensionField.identifier]
                : {}
            if (dimensionField.fieldType === DimensionFieldType.VALUE) {
                columnResponseDTO.value = value
            } else {
                columnResponseDTO.name = value
            }

            gridRow[dimensionField.identifier] = columnResponseDTO
        })

        return gridRow
    })
}

/**
 * Creates loadContent callback, that will be invoked if the popup will be opened
 *
 * @param action
 * @param filter
 * @param cancelTokenSource
 * @param paths
 * @param mainDimension
 * @param updatePopupConfigCallback - updates some data in the popup config after form config is loaded
 */
const createLoadContentCallback = (
    action: ActionDTO,
    filter: ConditionClauseDTO,
    cancelTokenSource: CancelTokenSource,
    paths: PathsDTO,
    mainDimension: DimensionDTO,
    updatePopupConfigCallback: (title: ReactNode, subtitle: ReactNode, formConfigDto: FormConfigDTO) => void,
) => {
    return () =>
        FormService.loadFormConfig(
            action.formType,
            filter,
            `${paths.serviceContextPath}${paths.base}${paths.form}`,
            UrlService.getGatewayUrl(),
            cancelTokenSource,
        ).then(async (formConfigDto: FormConfigDTO) => {
            const itemData =
                formConfigDto.type === FormConfigDTOTypeEnum.EDIT
                    ? await FormService.loadFormEntities(
                          filter,
                          DimensionService.getDimensionValueColumn(mainDimension.identifier),
                          formConfigDto,
                          `${paths.serviceContextPath}${paths.base}${paths.data}`,
                          UrlService.getGatewayUrl(),
                      )
                    : []

            updatePopupConfigCallback(
                PopupTextUtil.createTitle(formConfigDto, mainDimension, itemData),
                PopupTextUtil.createSubtitle(itemData),
                formConfigDto,
            )

            const uiFormConfig: UIFormConfig = {
                formConfig: formConfigDto,
                itemData: itemData,
                baseApi: `${paths.serviceContextPath}${paths.base}`,
                filter: filter,
            }

            return uiFormConfig
        })
}

/**
 * Creates action popup config for ActionType.OPEN_POPUP
 *
 * @param action
 * @param cancelTokenSource
 * @param selectedItems
 * @param additionalFilters
 * @param actions
 * @param paths
 * @param mainDimension
 * @param onAfterSubmit
 */
const createOpenPopupAction = (
    action: ActionDTO,
    cancelTokenSource: CancelTokenSource,
    selectedItems: GridDataRowDTO[],
    additionalFilters: ConditionClauseDTO,
    actions: ActionDTO[],
    paths: PathsDTO,
    mainDimension: DimensionDTO,
    onAfterSubmit?: () => void,
): ActionPopupConfig => {
    const identifier = `${mainDimension.identifier}__modal`
    const mergedFilters = createPopupAdditionalFilterClause(action, additionalFilters, mainDimension, selectedItems)

    const popupConfig = {
        identifier: identifier,
        additionalFilters: mergedFilters,
        setLoading: (loading: boolean): ModalAction => store.dispatch(modalLoadingState({ identifier, loading })),
        showContent: (content: UIFormConfig): ModalAction | void =>
            store.dispatch(modalContent({ identifier, content, popupConfig })),
        onAfterSubmit: onAfterSubmit,
        modalWidth: action.modalConfig?.modalWidth,
        modalMinHeight: action.modalConfig?.modalMinHeight,
        additionalFooterElements: action.modalConfig?.additionalFooterElements,
    } as ActionPopupConfig

    popupConfig.loadContent = createLoadContentCallback(
        action,
        mergedFilters,
        cancelTokenSource,
        paths,
        mainDimension,
        (title: ReactNode, subtitle: ReactNode, formConfigDto: FormConfigDTO) => {
            popupConfig.title = title
            popupConfig.subtitle = subtitle
            const keepOpenAfterCreateAndEdit =
                formConfigDto.keepOpenAfterCreateAndEdit && FormUtil.getIsEditOrCreateMode(formConfigDto)
            popupConfig.keepOpenAfterCreateAndEdit = keepOpenAfterCreateAndEdit

            if (keepOpenAfterCreateAndEdit) {
                popupConfig.onSubmitSuccess = (updatedItemData) => {
                    editLastCreatedOrUpdatedItem(
                        updatedItemData,
                        actions,
                        paths,
                        mainDimension,
                        cancelTokenSource,
                        onAfterSubmit,
                    )
                }
            }
        },
    )

    return popupConfig
}

const statusPopupOnConfirm = (submitData: DataRowDTO[], path: string) => {
    return FormService.submitForm(submitData, path, "PUT", UrlService.getGatewayUrl()).catch((response) => {
        if (response.message) {
            message.error(`An error occurred.`, 5)
            log.error(response.message)
        }
    })
}

/**
 * Rewrites actions field in the data list and set action handler
 *
 * @param dataSet
 * @param dataSettingsState
 * @param allActions
 * @param cancelTokenSource
 * @param onAfterSubmit
 * @param additionalFilters
 */
const enrichRowsWithActions = (
    dataSet: ReportingDataSetDTO,
    dataSettingsState: DataSettingsState,
    allActions: ActionDTO[],
    cancelTokenSource: CancelTokenSource,
    onAfterSubmit: () => void,
    additionalFilters?: ConditionClauseDTO,
) => {
    if (dataSet && dataSet.rows) {
        dataSet.rows = dataSet.rows.map((row) => {
            const inlineButtons: InlineButtonDTO[] = row[ACTIONS_FIELD].data as InlineButtonDTO[]
            if (inlineButtons) {
                row[ACTIONS_FIELD] = {
                    data: new RowActions(inlineButtons, (actionIdentifier: string) => {
                        try {
                            const widgetAction = allActions.find((action) => action.identifier == actionIdentifier)

                            if (widgetAction) {
                                invokeAction(
                                    widgetAction,
                                    dataSettingsState.settings.actions,
                                    dataSettingsState.settings.paths,
                                    dataSettingsState.mainDimension,
                                    [row],
                                    cancelTokenSource,
                                    onAfterSubmit,
                                    ConditionClauseService.filterConditionClauseBySupportedFilters(
                                        additionalFilters,
                                        Array.from(widgetAction.supportedAdditionalFilters || []),
                                    ),
                                )
                            } else {
                                log.error("Action with identifier '" + actionIdentifier + "' not found")
                            }
                        } catch (e) {
                            log.error("ERROR: ", e)
                            log.error("ACTION NOT FOUND: ", actionIdentifier)
                        }
                    }),
                }
            }

            return row
        })
    }
    return dataSet
}

const invokeAction = (
    action: ActionDTO,
    actions: ActionDTO[],
    paths: PathsDTO,
    mainDimension: DimensionDTO,
    selectedItems: GridDataRowDTO[],
    cancelTokenSource?: CancelTokenSource,
    onAfterSubmit?: () => void,
    additionalFilters?: ConditionClauseDTO,
): void => {
    if (action.type === ActionType.OPEN_POPUP) {
        // create / edit cases
        const popupConfig = createOpenPopupAction(
            action,
            cancelTokenSource,
            selectedItems,
            additionalFilters,
            // dataSettings,
            actions,
            paths,
            mainDimension,
            onAfterSubmit,
        )

        openPopupAction(popupConfig)
    } else if (isStatusAction(action)) {
        const actionModalConfig: ActionModalConfig = {
            action,
            selectedItems,
            mainDimension,
            apiPath: `${paths.serviceContextPath}${paths.base}`,
        }
        store.dispatch(openActionModal(actionModalConfig))
    } else {
        new Error(`Action type '${action.type}' not supported`)
    }
}

/**
 * Checks whether action is a status action
 *
 * @param action
 */
const isStatusAction = (action: ActionDTO) =>
    _includes([ActionType.DELETE, ActionType.DEACTIVATE, ActionType.ACTIVATE], action.type)

const ActionService = {
    enrichRowsWithActions: enrichRowsWithActions,
    invokeAction: invokeAction,
    statusPopupOnConfirm: statusPopupOnConfirm,
}

export default ActionService
