/**
 * See {@link https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking} for usage.
 */
export const assertExhaustive = (_value: never): never => {
    throw new Error("Exhaustiveness check failed")
}

export type NonEmptyArray<T> = [T, ...T[]]
export type NonEmptyReadonlyArray<T> = ReadonlyArray<T> & NonEmptyArray<T>

const isNonEmpty = <A>(arr: Array<A>): arr is NonEmptyArray<A> => arr.length > 0
