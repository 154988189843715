import React from "react"
import { PanelElementProperties, ToolAwarePanelElementProperties } from "domain/types"
import { ToolsContextProvider } from "domain/widget/ToolsContext"
import { TimeSpanElement } from "domain/widget/TimeSpanElement"
import GenericDataGridSearchForm from "domain/widget/generic/GenericDataGridSearchForm"
import { PanelToolbarComponent } from "shared/component/layout/PanelToolbarComponent"
import ContainerElement from "shared/component/layout/ContainerElement"
import ElementHeader from "shared/component/layout/ElementHeader"
import { FilterToolsContextContainer } from "domain/filter/component/FilterToolsContextContainer"

const ToolAwarePanelElement: React.FC<PanelElementProperties> = ({
    layoutElementConfig,
}: ToolAwarePanelElementProperties): JSX.Element => {
    const { timespanSettings } = layoutElementConfig
    const hasTools =
        layoutElementConfig.filters?.length > 0 ||
        timespanSettings ||
        layoutElementConfig.hasSearch ||
        layoutElementConfig.buttons?.length > 0

    return (
        <ToolsContextProvider
            key={layoutElementConfig.identifier}
            filters={layoutElementConfig.filters}
            timespanSettings={layoutElementConfig.timespanSettings}
        >
            <ElementHeader header={layoutElementConfig.header} />
            {hasTools && (
                <div className={"panel-controls"}>
                    <div className="panel-form-elements stretch-item">
                        <FilterToolsContextContainer />
                        {layoutElementConfig.hasSearch && <GenericDataGridSearchForm />}
                        <TimeSpanElement />
                    </div>
                    <div className="panel-toolbar">
                        <PanelToolbarComponent
                            disableButtons={false}
                            buttons={layoutElementConfig.buttons}
                            actions={Array.from(layoutElementConfig.actions || [])}
                        />
                    </div>
                </div>
            )}
            <ContainerElement layoutElementConfig={layoutElementConfig} />
        </ToolsContextProvider>
    )
}

export default ToolAwarePanelElement
