import { Size, SizeBreakpoint, SizeCategories, SizeCategory } from "domain/types/frontend/dimension.types"
import { DEFAULT_SIZE_BREAKPOINTS } from "domain/widget/ResponsiveWidgetSettingsUtil"

const ResponsiveSizeUtil = {
    getClassNameStringForSizeCategories: (sizeCategories: SizeCategories): string => {
        return (
            "width-" + sizeCategories.width.toLocaleLowerCase() + " height-" + sizeCategories.height.toLocaleLowerCase()
        )
    },

    getSizeCategories: (size: Size, breakpoints?: SizeBreakpoint[]): SizeCategories => {
        breakpoints = breakpoints || DEFAULT_SIZE_BREAKPOINTS
        return {
            width: ResponsiveSizeUtil.getSizeCategory(size.width, breakpoints),
            height: ResponsiveSizeUtil.getSizeCategory(size.height, breakpoints),
        }
    },

    /**
     * Find the first matching SizeCategory for the given size based within the defined breakpoints; it is up to the developer creating the
     * config to make sure that there are no overlapping or missing configurations
     * @param size
     * @param breakpoints
     */
    getSizeCategory: (size: number, breakpoints?: SizeBreakpoint[]): SizeCategory => {
        breakpoints = breakpoints || DEFAULT_SIZE_BREAKPOINTS
        let matchingSize: SizeCategory = null
        breakpoints.forEach((breakpoint) => {
            if (
                (size <= breakpoint.max && (size >= breakpoint.min || !breakpoint.min)) ||
                (size >= breakpoint.min && (size <= breakpoint.max || !breakpoint.max))
            ) {
                matchingSize = breakpoint.category
            }
        })
        if (matchingSize == null) {
            throw new Error(`Found no matching breakpoint for size  '${size}' in breakpoints`)
        }
        return matchingSize
    },
}

export default ResponsiveSizeUtil
