import * as React from "react"
import FilterComponentUtil from "domain/filter/component/FilterComponentUtil"
import { FilterState } from "domain/types"
import { Autocomplete as MuiAutocomplete, Box, Chip, CircularProgress, TextField } from "@mui/material"
import { ArrowDropDownOutlined } from "@mui/icons-material"
import { clsx } from "clsx"

type Props = {
    filter: FilterState
    label: string
    onChange?: (identifier: string, value: string | number | boolean | string[] | number[] | boolean[]) => void
    size?: any
    componentStyle?: any
    selectStyle?: any
    additionalCssClass?: string
    enableClearButton?: boolean
    multiple: boolean
}

export const SelectComponent: React.FC<Props> = React.memo((props: Props): JSX.Element => {
    const { filter, label, multiple } = props

    const filterIdentifier = FilterComponentUtil.getFilterFormValueColumn(filter)

    const handleChange = (value: string | number | boolean | string[] | number[] | boolean[]) => {
        const { onChange } = props
        if (onChange) onChange(filterIdentifier, value)
    }

    const hasSelectEntries =
        filter.filterEntries && filter.filterEntries.entries && filter.filterEntries.entries.length > 0
    const showIcon = filter.filterEntries?.entries?.some((row) => row.icon) === true
    const isLoading = !hasSelectEntries

    const options: Option[] = isLoading ? [] : createOptionList(filter)

    const getInitialLoadingValue = () => {
        return multiple ? [] : null
    }

    /**
     * Get the selected option for the filter value
     */
    const getSelectedValue = (): string | number | string[] | number[] => {
        if (isLoading) {
            return getInitialLoadingValue()
        } else {
            return filter.value === null || filter.value === undefined
                ? getInitialLoadingValue()
                : Array.isArray(filter.value)
                  ? multiple
                      ? filter.value
                      : filter.value.length > 0
                        ? filter.value[0]
                        : null
                  : filter.value
        }
    }

    const checkHasValue = (value: string | number | string[] | number[]): boolean => {
        if (value == null) {
            return false
        }

        if (Array.isArray(value)) {
            return value.length > 0
        }

        // If value is a string or number, it will be considered having a value if it's not empty or 0.
        // Adapt accordingly if this is not the desired behavior.
        return Boolean(value)
    }

    const selectedValue = getSelectedValue()
    const hasSelectedValue = checkHasValue(selectedValue)

    return (
        <div
            className={clsx(
                "filter-container",
                props.additionalCssClass,
                hasSelectedValue ? "has-value" : "no-value",
                "container-" + filterIdentifier,
            )}
            style={props.componentStyle || {}}
        >
            <Box className={"filter-wrapper " + (showIcon ? "has-icon" : "") + " " + filterIdentifier}>
                <MuiAutocomplete<string | number | boolean | string[] | number[] | boolean[]>
                    // @ts-expect-error TODO why doesn't this work?
                    multiple={multiple}
                    size={"small"}
                    id={filterIdentifier}
                    value={selectedValue}
                    loading={isLoading}
                    disabled={isLoading}
                    onChange={(_, value) => handleChange(value)}
                    options={options.map((option) => option.optionIdentifier)}
                    getOptionLabel={(optionIdentifierValue) =>
                        options.find((option) => option.optionIdentifier === optionIdentifierValue)?.optionLabel
                    }
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => {
                        // TODO: It might be possible to simplify this when this is fixed: https://github.com/mui/material-ui/issues/39833
                        const { key, ...rest } = props as React.HTMLAttributes<HTMLLIElement> & { key: string }
                        return (
                            <li key={key} {...rest}>
                                {getLabelComponent(filter, option)}
                            </li>
                        )
                    }}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index })
                            const labelComponent = getLabelComponent(filter, option)
                            return (
                                <Chip variant="filled" size={"small"} label={labelComponent} key={key} {...tagProps} />
                            )
                        })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={label}
                            name={filterIdentifier}
                            size={"small"}
                        />
                    )}
                    popupIcon={
                        isLoading ? <CircularProgress size={15} sx={{ marginTop: "5px" }} /> : <ArrowDropDownOutlined />
                    }
                />
            </Box>
        </div>
    )
})

const getLabelComponent = (
    filter: FilterState,
    value: string | number | boolean | string[] | number[] | boolean[],
): JSX.Element => {
    const entry = findEntry(filter, value)
    const label = entry === undefined ? value : FilterComponentUtil.getFilterValue(entry)
    const showIcon = filter.filterEntries?.entries?.some((row) => row.icon) === true

    return (
        <>
            {showIcon && entry && (
                <div className={"icon-wrapper"}>
                    <div className={`icon ${entry.icon}`}>&nbsp;</div>
                </div>
            )}
            <span className={"option-name"}>{label}</span>
        </>
    )
}

/**
 * Finds filter entry by value
 *
 * @param filter
 * @param value
 */
const findEntry = (filter: FilterState, value: string | number | boolean | string[] | number[] | boolean[]) => {
    return filter.filterEntries?.entries?.find((row) => row.value === value)
}

const createOptionList = (filter: FilterState): Option[] => {
    return (
        filter.filterEntries?.entries?.map((option) => {
            return {
                optionIdentifier: option.value,
                optionLabel: FilterComponentUtil.getFilterValue(option),
            }
        }) || []
    )
}

type Option = {
    optionIdentifier: string | number | boolean
    optionLabel: string
}
