import React, { useContext } from "react"
import { WidgetSettingsPanel } from "domain/widget/WidgetSettingsPanel"
import { Form, Radio, Switch, Tooltip } from "antd"
import { AlignLeftOutlined } from "@ant-design/icons"
import { v1 as uuid } from "uuid"
import { WidgetContext } from "domain/widget/WidgetContext"
import { BarDirection, ColorTheme, TopNBarWidgetSettingsDTO } from "domain/types/backend/widget.types"
import _cloneDeep from "lodash/cloneDeep"
import { ContainerSizeContext } from "domain/widget/ContainerSizeContext"

/**
 * @constructor
 */
export const TopNBarWidgetSettingsPanel = () => {
    const containerSizeContext = useContext(ContainerSizeContext)
    const widgetDataContext = useContext(WidgetContext)
    const { widgetSettings, updateWidgetSettings } = widgetDataContext
    const topNBarWidgetSettings = widgetSettings as TopNBarWidgetSettingsDTO

    if (!containerSizeContext.containerSize.height || !containerSizeContext.containerSize.width) {
        return null
    }

    let labelColWidth = 8
    const isHorizontalLayout = containerSizeContext.containerSize.width > 220
    if (containerSizeContext.containerSize.width < 300) {
        labelColWidth = 13
    } else if (containerSizeContext.containerSize.width < 350) {
        labelColWidth = 10
    }
    const formItemLayout = isHorizontalLayout
        ? {
              labelCol: { span: labelColWidth },
              wrapperCol: { span: 20 },
          }
        : {}
    const onValuesChange = (changedValues, allValues) => {
        const newSettings: TopNBarWidgetSettingsDTO = _cloneDeep(topNBarWidgetSettings)
        for (const key in changedValues) {
            newSettings[key] = changedValues[key]
        }
        updateWidgetSettings(newSettings)
    }
    const createThemeOptions = () => {
        const themeOptions = [
            { label: "Multi-Colored", value: ColorTheme.MULTICOLOR },
            { label: "Red", value: ColorTheme.MONOCHROME_RED },
            { label: "Orange", value: ColorTheme.MONOCHROME_ORANGE },
            { label: "Yellow", value: ColorTheme.MONOCHROME_YELLOW },
            { label: "Green", value: ColorTheme.MONOCHROME_GREEN },
            { label: "Turquoise", value: ColorTheme.MONOCHROME_TURQUOISE },
            { label: "Blue", value: ColorTheme.MONOCHROME_BLUE },
            { label: "Purple", value: ColorTheme.MONOCHROME_PURPLE },
            { label: "Gray", value: ColorTheme.MONOCHROME_GRAY },
        ]

        return (
            <Radio.Group className={"theme-selector"}>
                {themeOptions.map((themeOption) => (
                    <Radio.Button value={themeOption.value} key={uuid()}>
                        <Tooltip title={themeOption.label}>
                            <div className={"theme-option theme-" + themeOption.value}>
                                {themeOption.value == ColorTheme.MULTICOLOR ? (
                                    <span className={"multicolor-bars"}>
                                        <div className={"bars"}>
                                            <div> </div>
                                            <div> </div>
                                            <div> </div>
                                            <div> </div>
                                            <div> </div>
                                        </div>
                                    </span>
                                ) : (
                                    <span className={"hidden-label"}>{themeOption.label}</span>
                                )}
                            </div>
                        </Tooltip>
                    </Radio.Button>
                ))}
            </Radio.Group>
        )
    }
    return (
        <WidgetSettingsPanel>
            <Form
                {...formItemLayout}
                size={"small"}
                layout={isHorizontalLayout ? "horizontal" : "vertical"}
                onValuesChange={onValuesChange}
                className={
                    containerSizeContext.containerSize.width < 400 || containerSizeContext.containerSize.height < 400
                        ? "condensed-form"
                        : ""
                }
                initialValues={{
                    barDirection: topNBarWidgetSettings.barDirection,
                    topNElements: topNBarWidgetSettings.topNElements,
                    stacked: topNBarWidgetSettings.stacked,
                    showOthers: topNBarWidgetSettings.showOthers,
                    showDecalPattern: topNBarWidgetSettings.showDecalPattern,
                    theme: topNBarWidgetSettings.theme,
                    showValues: topNBarWidgetSettings.showValues,
                }}
            >
                <Form.Item name="barDirection" label={"Bar direction"}>
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value={BarDirection.VERTICAL}>
                            <Tooltip title={"Vertical"}>
                                <AlignLeftOutlined style={{ transform: "rotate(-90deg)" }} />
                            </Tooltip>
                        </Radio.Button>
                        <Radio.Button value={BarDirection.HORIZONTAL}>
                            <Tooltip title={"Horizontal"}>
                                <AlignLeftOutlined />
                            </Tooltip>
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="topNElements" label={"Max. entries"}>
                    <Radio.Group buttonStyle="solid" className={"additional-padding"}>
                        <Radio.Button value={5}>5</Radio.Button>
                        <Radio.Button value={10}>10</Radio.Button>
                        <Radio.Button value={20}>20</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="showOthers" valuePropName="checked" label={"Show others"}>
                    <Switch
                        defaultChecked={topNBarWidgetSettings.showOthers}
                        title={"Show other items grouped as additional bar"}
                    />
                </Form.Item>

                <Form.Item name="stacked" valuePropName="checked" label={"Stack bars"}>
                    <Switch defaultChecked={topNBarWidgetSettings.stacked} title={"Stack bars on top of each other"} />
                </Form.Item>

                <Form.Item name="showValues" valuePropName="checked" label={"Show values"}>
                    <Switch
                        defaultChecked={topNBarWidgetSettings.showValues}
                        title={"Show numeric values for each bar"}
                    />
                </Form.Item>

                <Form.Item name="theme" label={"Theme"}>
                    {createThemeOptions()}
                </Form.Item>

                <Form.Item name="showDecalPattern" valuePropName="checked" label={"Show pattern"}>
                    <Switch defaultChecked={topNBarWidgetSettings.showDecalPattern} />
                </Form.Item>
            </Form>
        </WidgetSettingsPanel>
    )
}
