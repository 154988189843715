import React, { ReactNode, useContext } from "react"
import { WidgetContext } from "domain/widget/WidgetContext"
import { DataListWidgetSettingsDTO } from "domain/types/backend/widget.types"
import { List } from "antd"
import { Widget } from "domain/widget/Widget"

type Props = {
    children?: ReactNode
}

export const DataListWidget = (props: Props) => {
    const widgetDataContext = useContext(WidgetContext)
    const { response, isLoading } = widgetDataContext
    const widgetSettings = widgetDataContext.widgetSettings as DataListWidgetSettingsDTO

    /*
    data class JenkinsStatusSummary(
        var totalJobCount: Int,
        var failingJobCount: Int,
        var jobDetails: List<JenkinsJobSummary>
    )

    class JenkinsJobSummary(
        val id: Int,
        val name: String,
        val jenkinsHost: String,
        val lastBuild: LocalDateTime?,
        val lastSuccess: LocalDateTime?,
        val lastFailure: LocalDateTime?,
        val lastComment: String,
        val lastResult: JenkinsBuildState,
        val lastFailureCount: Int?,
        val lastSuccessCount: Int?,
        val lastSkippedCount: Int?,
        val lastDuration: Int?,
        val lastBuildNumber: Int,
        val lastBuildId: Int,
    ) {

     */
    // todo carina get as config from server
    const itemRowsKey = "response.dataSet.rows[0].summary.value.jobDetails"
    const items = response ? eval(itemRowsKey) : undefined
    const headerTemplate =
        "${response.dataSet.rows[0].summary.value.failingJobCount} of ${response.dataSet.rows[0].summary.value.totalJobCount} jobs are failing"
    const descriptionTemplate = "lastResult: ${item.lastResult} at ${item.lastBuild}"
    const titleTemplate = "${item.name}"
    const linkTargetTemplate = "${item.buildLink}"

    return (
        <div>
            <Widget isLoading={isLoading} response={response}>
                {/*<div style={{height: 400, width: 1600}}>*/}
                {/*    <TimeLineWidget/>*/}
                {/*</div>*/}
                <div className={"data-list-widget"} style={{ width: "500px" }}>
                    <List
                        header={<div>{response ? eval("`" + headerTemplate + "`") : undefined}</div>}
                        footer={<div>{widgetSettings.listFooter}</div>}
                        bordered
                        loading={isLoading}
                        size="small"
                        dataSource={items}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={eval("`" + titleTemplate + "`")}
                                    description={<span>{eval("`" + descriptionTemplate + "`")} </span>}
                                />
                                <div>
                                    <a target={"_blank"} href={eval("`" + linkTargetTemplate + "`")}>
                                        Show
                                    </a>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            </Widget>
        </div>
    )
}
