import React from "react"
import { ActionDTOIdentifierEnum, ColumnConfigDTO } from "generated/models"
import { useDataGridContext } from "domain/widget/generic/DataGridContext"
import { Empty } from "antd"
import { RowActions } from "domain/actions/RowActions"
import DimensionService from "domain/dimension/service/DimensionService"
import { DataGridComponent } from "domain/datagrid/component/DataGridComponent"
import { CustomColumnMenu } from "shared/component/mui/datagrid/CustomColumnMenu"
import { GridPinnedColumnFields } from "@mui/x-data-grid-pro"

export const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
        <Empty description={"No results found"} />
    </div>
)

export const SELECTED_FIELD = "__selected"
export const SELECTED_ID_FIELD = DimensionService.getDimensionValueColumn(SELECTED_FIELD)
export const ACTIONS_FIELD = "__actions__"
export const ACTIONS_ID_FIELD = DimensionService.getDimensionValueColumn(ACTIONS_FIELD)
export const HEALTH_FIELD = "health"
export const HEALTH_ID_FIELD = DimensionService.getDimensionValueColumn(HEALTH_FIELD)
export const CONTEXT_MENU_FIELD = "__context_menu__"
export const CONTEXT_MENU_FIELD_ID = DimensionService.getDimensionValueColumn(CONTEXT_MENU_FIELD)

export type DataGridProps = {
    columns: Array<ColumnConfigDTO>
    sortAscending?: boolean | undefined
    sortProperties?: string[] | undefined
    onSort?: (orderBy: string, sortAscending: boolean) => void
    onClickOnContextMenuAction?: (actionIdentifier: ActionDTOIdentifierEnum, invokeRowIndices: number[]) => void
    customColumnMenu?: CustomColumnMenu
    pinnedColumns?: GridPinnedColumnFields
    onPinnedColumnsChange?: (pinnedColumns: GridPinnedColumnFields) => void
}

export type Row = {
    rowActions: RowActions
    rowIndex: number
}

export const DataGrid: React.FC<DataGridProps> = React.memo((props: DataGridProps): JSX.Element => {
    const { getSelectedRowIndices, onSelect, getRows } = useDataGridContext()

    const {
        columns,
        sortAscending,
        sortProperties,
        onSort,
        onClickOnContextMenuAction,
        customColumnMenu,
        pinnedColumns,
        onPinnedColumnsChange,
    } = props

    return (
        <DataGridComponent
            rows={getRows()}
            selectedRowIndices={getSelectedRowIndices()}
            onSelect={onSelect}
            onSort={onSort}
            columns={columns}
            sortAscending={sortAscending}
            sortProperties={sortProperties}
            onClickOnContextMenuAction={onClickOnContextMenuAction}
            customColumnMenu={customColumnMenu}
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={onPinnedColumnsChange}
        />
    )
})
