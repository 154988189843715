import { QuerySettingsDTO } from "generated/models"
import { post } from "shared/service/api"
import store from "shared/redux/store"
import { CancelTokenSource } from "axios"
import UrlService from "shared/service/url.service"
import { LoadResponseDTOReportingDataSetDTO } from "generated/models"

// TODO: Find a better name for this service

/**
 * Loads reporting slice data
 *
 * @param apiPath
 * @param querySettingsDTO
 * @param cancelTokenSource
 */
const loadData = (
    apiPath: string,
    querySettingsDTO: QuerySettingsDTO,
    cancelTokenSource?: CancelTokenSource,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    const settings = querySettingsDTO.appContext
        ? querySettingsDTO
        : { ...querySettingsDTO, appContext: store.getState().appContext.appContext }

    return post<LoadResponseDTOReportingDataSetDTO>(`${UrlService.getUiServiceApiUrl()}/${apiPath}`, settings, {
        cancelToken: cancelTokenSource?.token,
    })
}

const ReportingService = {
    loadData: loadData,
}

export default ReportingService
