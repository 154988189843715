import React, { useContext } from "react"
import { ActionDTO, ActionDTOIdentifierEnum, ToolbarButtonDTO } from "generated/models"
import ToolbarButtonComponent from "shared/component/ToolbarButtonComponent"
import { Button } from "antd"
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons"
import { ToolbarButton } from "domain/types/backend/layout.types"
import { ToolsContext } from "domain/widget/ToolsContext"

type Props = {
    buttons: ToolbarButtonDTO[]
    actions: ActionDTO[]
    disableButtons?: boolean
}

export const PanelToolbarComponent: React.FC<Props> = React.memo((props: Props): JSX.Element => {
    const toolsContext = useContext(ToolsContext)

    const invokeAction = (action: ActionDTO) => {
        toolsContext.updateActionState(action)
    }

    const onDownload = () => {
        toolsContext?.updateDownloadProcessing(true)
    }

    const createToolbarButtonComponent = (button: ToolbarButtonDTO, index: number, disabled: boolean) => {
        const action = props.actions.find((action) => action.identifier === button.actionIdentifier)
        if (!action) {
            throw new Error("No action was found for the button actionIdentifier " + button.actionIdentifier)
        }

        const toolbarButton = { toolbarButtonDTO: button, action: action } as ToolbarButton

        return (
            <ToolbarButtonComponent
                disabled={disabled}
                button={toolbarButton}
                onInvoke={invokeAction}
                selectedRows={0}
                key={index}
            />
        )
    }

    const { buttons, disableButtons } = props

    const configuredButtons = buttons.map((button, index) => {
        if (button.actionIdentifier == ActionDTOIdentifierEnum.DOWNLOAD) {
            return (
                <div className={"toolbar-button-wrapper default-wrapper"} aria-label="Download" key={"download_button"}>
                    <Button
                        className="toolbar-button"
                        type="default"
                        htmlType="button"
                        onClick={onDownload}
                        disabled={disableButtons || toolsContext?.downloadProcessing}
                    >
                        {toolsContext?.downloadProcessing ? <LoadingOutlined /> : <DownloadOutlined />}
                        <span className="toolbar-button-text">Download</span>
                    </Button>
                </div>
            )
        } else {
            return createToolbarButtonComponent(button, index, disableButtons)
        }
    })

    return <div className="toolbar">{configuredButtons}</div>
})
