import React, { useContext } from "react"
import moment, { Moment } from "moment" // TODO moment is legacy. Is there a replacement?
import { ToolsContext } from "domain/widget/ToolsContext"
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker"
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField"
import {
    DateRangeValidationError,
    LocalizationProvider,
    PickerChangeHandlerContext,
    PickersShortcutsItem,
} from "@mui/x-date-pickers-pro"
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment"
import { DateRange } from "@mui/x-date-pickers-pro"
import { TimeUtil } from "shared/util/TimeUtil"

export const TimeSpanElement = () => {
    const toolsContext = useContext(ToolsContext)
    const { updateTimespanSettings, timespanSettings } = toolsContext

    const displayDateFormat = "DD.MM.YYYY"

    const [error, setError] = React.useState<DateRangeValidationError | null>(null)
    const hasError = error !== null && (error[0] !== null || error[1] !== null)
    const errorMessage = hasError ? "Start date must be 2010-01-01 or later. End date must be today or earlier." : ""

    const handleRangePickerChange = (
        values: Moment[],
        context: PickerChangeHandlerContext<DateRangeValidationError>,
    ) => {
        if (context.validationError[0] !== null || context.validationError[1] !== null) {
            return
        }

        const newTimeSpan = {
            start: TimeUtil.toISOLocalString(values[0].clone().startOf("day")),
            end: TimeUtil.toISOLocalString(values[1].clone().endOf("day")),
        }
        updateTimespanSettings(newTimeSpan)
    }

    const shortcutsItems: PickersShortcutsItem<DateRange<Moment>>[] = [
        {
            label: "Yesterday",
            getValue: () => {
                return [moment().subtract(1, "day"), moment().subtract(1, "day")]
            },
        },
        {
            label: "Today",
            getValue: () => {
                return [moment(), moment()]
            },
        },
        {
            label: "This Month",
            getValue: () => {
                return [moment().startOf("month"), moment()]
            },
        },
        {
            label: "Last Month",
            getValue: () => {
                return [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
            },
        },
        {
            label: "Last 3 Months",
            getValue: () => {
                return [moment().subtract(3, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
            },
        },
        {
            label: "This Week",
            getValue: () => {
                return [moment().startOf("week"), moment()]
            },
        },
        {
            label: "Last Week",
            getValue: () => {
                return [moment().subtract(1, "week").startOf("week"), moment().subtract(1, "week").endOf("week")]
            },
        },
    ]

    return timespanSettings ? (
        <div style={{ width: "220px" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateRangePicker
                    onChange={handleRangePickerChange}
                    onError={(newError) => setError(newError)}
                    slots={{
                        field: SingleInputDateRangeField,
                    }}
                    slotProps={{
                        shortcuts: {
                            items: shortcutsItems,
                        },
                        textField: { fullWidth: true, size: "small", helperText: errorMessage },
                        actionBar: { actions: [] },
                    }}
                    disableFuture
                    minDate={moment("2010-01-01", "YYYY-MM-DD")}
                    format={displayDateFormat}
                    value={[moment(timespanSettings.start), moment(timespanSettings.end)]}
                />
            </LocalizationProvider>
        </div>
    ) : (
        <div />
    )
}
